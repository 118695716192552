import React from "react";
import profileImage from "../assets/profileimage.jpg"

function Home() {
  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans p-8">
      {/* Header */}
      <header className="text-center mb-12">
      <img
          src={profileImage}
          alt="Profile"
          className="mx-auto w-32 h-32 rounded-full border border-gray-300"
        />
        <div className="text-4xl font-bold">Aleena Alby</div>
        <div className="text-gray-700 mt-1">Software Engineer</div>
        <div className="flex justify-center mt-4 space-x-4">
          {/* <a href="#" className="text-gray-500 hover:text-black">
            Twitter
          </a> */}
          <a href="https://github.com/AleenaAlby" className="text-gray-500 hover:text-black">
            GitHub
          </a>
        </div>
      </header>

      <main className="max-w-2xl mx-auto">
        <p className="text-lg leading-relaxed">Hi there!</p>
        <p className="text-lg leading-relaxed mt-4">
            My name is Aleena, a <span className="text-red-500">software engineer </span> 
            building innovative solutions in <span className="text-red-500">Quantum Computing as a Service (QCaaS)</span>, 
            where I get to combine cutting-edge technology with my passion for problem-solving.
            My work revolves around making quantum computing more accessible and 
            impactful for real-world applications.
        </p>
        {/* List of Writings */}
        {/* <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">Here are some things I've written:</h2>
          <ul className="space-y-4">
            <li>
              <a href="#" className="text-red-500 hover:underline">
                The Hole in the Sky That We Actually Fixed
              </a>
              <div className="text-sm text-gray-500">May 17, 2024</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                For Sale: A Promise to Remove Invisible Gas
              </a>
              <div className="text-sm text-gray-500">May 31, 2023</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                Debugging Misadventures: Down the Rabbit Hole
              </a>
              <div className="text-sm text-gray-500">May 15, 2023</div>
            </li>
          </ul>
        </div> */}
      </main>
    </div>
  );
}

export default Home;
